import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Innovation() {
  // Settings for the slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="section">
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-4 text-start mt-md-5 pt-md-4">
            <div className="section-title mb-4 pb-2 text-center text-md-start">
              <h4 className="title my-4">Why Smart Insoles?</h4>
              <p className="text-muted para-desc mb-0 mx-auto">
                Unlike passive footwear, WellSole smart insoles use cutting-edge
                sensors to provide real-time feedback on performance, health,
                and biomechanics. Whether you're an athlete or healthcare
                professional, WellSole empowers you with insights that passive
                footwear cannot offer.
              </p>
            </div>
          </div>
          <div className="col-md-8 order-first order-md-2">
            <img
              src="assets/images/comparison-img.png"
              className="img-fluid mx-auto d-block"
              width={"100%"}
              alt=""
            />
          </div>
        </div>
        <div className="row align-items-center mt-5 slider-custom">
          <div className="col-lg-5 col-md-6 mt-4 pt-2 ">
            <Slider {...settings}>
              <div>
                <img
                  src="assets/images/app1.png"
                  className="img-fluid mx-auto d-block"
                  width={"80%"}
                  alt="App Screen 2"
                />
              </div>
              <div>
                <img
                  src="assets/images/app2.png"
                  className="img-fluid mx-auto d-block"
                  width={"80%"}
                  alt="App Screen 3"
                />
              </div>
              <div>
                <img
                  src="assets/images/app3.png"
                  className="img-fluid mx-auto d-block"
                  width={"80%"}
                  alt="App Screen 4"
                />
              </div>
              <div>
                <img
                  src="assets/images/app4.png"
                  className="img-fluid mx-auto d-block"
                  width={"80%"}
                  alt="App Screen 5"
                />
              </div>
              <div>
                <img
                  src="assets/images/app5.png"
                  className="img-fluid mx-auto d-block"
                  width={"80%"}
                  alt="App Screen 6"
                />
              </div>
              <div>
                <img
                  src="assets/images/app6.png"
                  className="img-fluid mx-auto d-block"
                  width={"80%"}
                  alt="App Screen 7"
                />
              </div>
              {/* Add more images as needed */}
            </Slider>
          </div>

          <div className="col-lg-7 col-md-6 mt-4 pt-2">
            <div className="section-title ms-lg-5 text-center text-md-start">
              <h4 className="title mb-4">
                Transforming Footwear <br /> with Innovation
              </h4>
              <p className="text-muted">
                WellSole’s smart insoles provide superior data on performance,
                wellness, and biomechanics. Our advanced technology offers
                real-time insights, making WellSole the smarter choice over
                traditional footwear. Discover how WellSole can transform your
                experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Innovation;
