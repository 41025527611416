import React from "react";
import { Link } from "react-router-dom";

function Mission() {
  return (
    <div className="container mt-100">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h4 className="title mb-4">Revolutionizing Footwear Innovation</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
            WellSole collaborates with third-party contractors and leading brands to integrate advanced smart insole technology. We empower footwear manufacturers with tools to enhance performance and wellness without owning their manufacturing facilities.
            </p>
          </div>
        </div>
      </div>
      <div className="row align-items-center" id="counter">
        <div className="col-md-6">
          <img
            src="assets/images/mission.jpg"
            className="img-fluid rounded"
            alt="Mission"
          />
        </div>

        <div className="col-md-6 mt-5 pt-2 mt-sm-0 pt-sm-0">
          <div className="ms-lg-4">
            <div className="d-flex">
              <span className="h6 align-self-end ms-2">How Does Wellsole Work?</span>
            </div>
            <div className="section-title">
              <h4 className="title mb-4">Our Mission and Vision</h4>
              <p className="text-muted">
                At <span className="text-primary">WellSole</span>, our mission
                is to empower shoe manufacturers by providing innovative smart
                insole technology that enhances performance and wellness. We
                envision a future where every step taken in a pair of shoes
                equipped with WellSole technology is a step towards better
                health and superior performance. Our commitment to excellence
                drives us to continuously innovate and push the boundaries of
                what footwear can achieve.
              </p>
              <Link to="/contact" className="btn btn-primary mt-3">
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
