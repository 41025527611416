import React from "react";

function Partner() {
  return (
    <div className="container mt-100 pt-0 overflow-hidden">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h4 className="title mb-4">Elevate with WellSole</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              WellSole offers shoe manufacturers and third-party contractors the
              opportunity to integrate cutting-edge smart insole technology into
              their products. Enhance your product line, differentiate your
              brand, and provide added value to your customers with our
              innovative solutions
            </p>
          </div>
        </div>
      </div>
      <div className="row align-items-center mt-100 pt-5">
        <div className="col-lg-5 order-1 order-lg-2 mt-4 pt-2 mt-lg-0 pt-lg-0">
          <div className="section-title me-lg-4">
            <h1 className="title mb-3">Why Partner with WellSole?</h1>
            <p className="para-desc text-muted">
              Partnering with WellSole offers footwear brands a unique
              opportunity to elevate customer satisfaction and drive revenue
              growth through seamless integration of smart insole technology.
              With real-time actionable data, WellSole enhances user experience
              by providing insights that empower users to make informed choices.
              Thus, building brand loyalty and fostering a committed customer
              base. Seamless integration and reliable connectivity ensure that
              adding WellSole to your footwear is simple and effective.
            </p>
          </div>
        </div>

        <div className="col-lg-7 order-2 order-lg-1">
          <div className="saas-feature-shape-left position-relative">
            <img
              src="assets/images/partner.jpg"
              className="img-fluid mx-auto d-block rounded shadow"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partner;
