import React from "react";

function Features() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h4 className="title mb-4">Enhance Athletic Performance</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              Whether you want to improve your sprinting technique or quickly
              remedy your golf swing or jump shot, Wellsole has you covered.
              Gain an immediate competitive edge by using real-time
              individualized data with smart feedback.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-12">
          <div className="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
            <span className="h1 icon-color">
              <i className="uil uil-analytics"></i>
            </span>
            <div className="card-body p-0 content">
              <h5>Gait Analysis</h5>
              <p className="para text-muted mb-0">
                Analyze your gait to identify inefficiencies and improve your
                stride
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div className="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
            <span className="h1 icon-color">
              <i className="uil uil-refresh"></i>
            </span>
            <div className="card-body p-0 content">
              <h5>Impact Absorption</h5>
              <p className="para text-muted mb-0">
                Monitor impact forces to optimize your training and reduce
                injury risks.
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
          <div className="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
            <span className="h1 icon-color">
              <i className="uil uil-tachometer-fast-alt"></i>
            </span>
            <div className="card-body p-0 content">
              <h5>Performance Metrics</h5>
              <p className="para text-muted mb-0">
              Track key performance metrics such as speed, ground reaction force and cadence.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center pt-5 mt-5">
        <div className="d-flex justify-content-md-end col-md-8 ">
          <img
            src="assets/images/2miles.png"
            className="img-fluid mt-4 w-75 d-none d-md-block mt-md-0 rounded-md shadow-lg"
            alt=""
          />
          <img
            src="assets/images/2miles.png"
            className="img-fluid mt-4 w-100 d-block d-md-none mt-md-0 rounded-md shadow-lg"
            alt=""
          />
        </div>

        <div className="col-lg-4 order-first col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div className="section-title text-start ms-lg-4">
            <h4 className="title mb-4">Detailed Gait Analysis</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              WellSole insoles go beyond basic data like calories. With advanced
              metrics such as Heel Strike, Toe Off, and Peak Pressure tracking,
              you can identify inefficiencies in your movement and improve
              performance. Whether you're walking or running, WellSole tracks
              every detail.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
