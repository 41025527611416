import React from "react";

function Benefits() {
  return (
    <section className="section">
      <div className="container pb-5 mb-md-5">
        <div className="row justify-content-center mb-5 mt-0">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">
                The Benefits of WellSole Technology
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-12">
            <div className="features feature-primary text-center">
              <div className="image position-relative d-inline-block">
                <i className="uil uil-arrow-growth h1 text-primary"></i>
              </div>

              <div className="content mt-4">
                <h5>Enhanced Performance</h5>
                <p className="text-muted mb-0">
                  Optimize athletic performance with real-time feedback and
                  detailed gait analysis.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-12 mt-5 mt-sm-0">
            <div className="features feature-primary text-center">
              <div className="image position-relative d-inline-block">
                <i className="uil uil-heartbeat h1 text-primary"></i>
              </div>

              <div className="content mt-4">
                <h5>Innovative Ideas</h5>
                <p className="text-muted mb-0">
                  Monitor health metrics and receive personalized insights to
                  improve overall well-being.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-12 mt-5 mt-sm-0">
            <div className="features feature-primary text-center">
              <div className="image position-relative d-inline-block">
                <i className="uil uil-comment-alt-heart h1 text-primary"></i>
              </div>

              <div className="content mt-4">
                <h5>Comfort and Support</h5>
                <p className="text-muted mb-0">
                  Discover a new level of comfort and support with our
                  precision-crafted insoles.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-12 mt-5 mt-sm-0">
            <div className="features feature-primary text-center">
              <div className="image position-relative d-inline-block">
                <i className="uil uil-cloud-lock h1 text-primary"></i>
              </div>

              <div className="content mt-4">
                <h5>Reliable Data</h5>
                <p className="text-muted mb-0">
                  Trust in the accuracy and reliability of our scientifically
                  validated technology.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
