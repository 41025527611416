import React from "react";
import Section from "../common/Section";

function AthleticPerformance() {
  return (
    <div id="sports" className="mt-100">
      <Section
        title="Enhancing Athletic Performance"
        description={
          "Athletes at all levels can benefit from WellSole's smart technology. Our insoles provide detailed performance metrics, helping athletes optimize their training and prevent injuries"
        }
        image={"assets/images/atheletic.jpg"}
      />
      <div className="container mt-100 mt-60">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">
                Applications in Sports and Athletics
              </h4>
              <p className="fw-bold">“Practice does not make perfect. Only perfect practice makes perfect.” – Vince Lombardi</p>
              <h4 className=" mt-5">
              Faster Improvement from Practice:
              </h4>
              <img alt="steps" className="w-100" src="assets/images/steps.png"/>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-dashboard rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">Performance Tracking</h5>
                <p className="text-muted mt-2">
                  Monitor key metrics such as speed, distance, and impact forces
                  to improve athletic performance
                </p>
                <i className="uil uil-dashboard full-img"></i>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-band-aid rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">Injury Prevention</h5>
                <p className="text-muted mt-2">
                  Identify and correct biomechanical inefficiencies to reduce
                  the risk of serious injuries
                </p>
                <i className="uil uil-band-aid full-img"></i>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-exchange rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">Training Optimization</h5>
                <p className="text-muted mt-2">
                  Receive personalized feedback to enhance training routines and
                  achieve peak performance.
                </p>
                <i className="uil uil-exchange full-img"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AthleticPerformance;
