import React from "react";
import Header from "../components/common/Header";
import Hero from "../components/common/Hero";
import Cta from "../components/Home/Cta";
import Footer from "../components/common/Footer";
import Mission from "../components/About/Mission";
import Team from "../components/About/Team";
import Proven from "../components/About/Proven";

function About() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Hero
        title="ABOUT US"
        heading="Our Journey to Innovation"
        image={"about.jpg"}
      />
      <Mission/>
      <Team/>
      {/* <Award/> */}
      <Proven/>
      <Cta/>
      <Footer/>
    </div>
  );
}

export default About;
