import React from "react";

function Research() {
  return (
    <div className="container mt-100 pt-0 overflow-hidden">
      <div className="row align-items-center">
        <div className="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
          <div className="section-title me-lg-4">
            <h1 className="title mb-3">Validated by Scientific Research</h1>
            <p className="para-desc text-muted">
              The Wellsole was carefully engineered, calibrated and validated at
              The University of Massachusetts Center for Human Health and
              Performance. The reliability, repeatability and accuracy of the
              Wellsole was measured against the gold standard pressure measuring
              floor tile system in the UMass laboratory.
            </p>
            <p className="para-desc text-muted">
              To ensure the Wellsole’s ability to collect data in real-time,
              human test subjects wore the WellSole insoles for 4 weeks outside
              of the lab. The results demonstrated minimal impact from wear on
              sensor calibration, sensitivity, and responsiveness. We are proud
              to say the WellSole maintained its’ structural integrity and
              electronics platform performance while collecting accurate,
              reliable and repeatable biomechanical data.
            </p>
          </div>
        </div>

        <div className="col-lg-7 order-1 order-lg-2">
          <div className="saas-feature-shape-right position-relative">
            <img
              src="assets/images/research.jpg"
              className="img-fluid mx-auto d-block rounded shadow"
              alt="Research"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Research;
